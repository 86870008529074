<template>
  <div class="goods-edit-draw">
    <page-draw @changedDrawer="changedDrawer" :isShow="pageDialogVisible">
      <div class="goods-detail">
        <div class="top-form">
          <el-button @click="handleEditClick" type="primary" v-has="'goods:edit'">编辑</el-button>
          <el-button v-if="updateGoodsDetail && updateGoodsDetail.goodStatus == 1" @click="handleOperateClick(2)"
            type="info">下架</el-button>
          <el-button v-else @click="handleOperateClick(1)" type="primary">上架</el-button>
        </div>
        <div class="goods-img">
          <page-swiper :picList="goodsPicList"></page-swiper>
        </div>
        <div class="goods-name">{{ updateGoodsDetail && updateGoodsDetail.goodName }}</div>
        <div class="base-info">
          <div class="title">
            <div>基本信息</div>
          </div>
          <div class="base-info-list">
            <div class="info-item">
              <div class="item-name">分类:</div>
              <div class="value">
                <template :key="item" v-for="(item, index) in updateGoodsDetail && updateGoodsDetail.categoryNameList">

                  <span>{{ item.kindName }}</span>
                  <span v-if="index == 0">/</span>
                </template>
              </div>
            </div>
            <div class="info-item">
              <div class="item-name">标签:</div>
              <div v-if="currentGoodsTag" class="value">{{ currentGoodsTag.tagName }}</div>
            </div>
            <div class="info-item">
              <div class="item-name">销量:</div>
              <div class="value">999</div>
            </div>
            <div class="info-item">
              <div class="item-name">网站展示:</div>
              <div class="value">展示</div>
            </div>
          </div>
        </div>

        <div class="format-info">
          <div class="title">
            <div>规格</div>
          </div>
          <div class="titles">
            <div>规格</div>
            <div>参数</div>
            <div>售价</div>
            <div>划线价</div>
          </div>
          <div class="format-list">
            <template :key="item" v-for="item in goodsFormatList">
              <div class="item">
                <div>{{ item.paraName }}</div>
                <div>{{ item.paraDetail }}</div>
                <div>{{ item.salePrice }}</div>
                <div>{{ item.promPrice }}</div>
              </div>
            </template>
          </div>
        </div>

        <div class="goods-desc">
          <div class="title">
            <div>产品介绍</div>
          </div>
          <div v-html="updateGoodsDetail && updateGoodsDetail.goodInfo"></div>
        </div>
      </div>
    </page-draw>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import PageDraw from '@/components/page-drawer/page-drawer.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { categoryProps } from './config'
import { useRouter } from 'vue-router'
import emitter from '@/utils/eventbus'
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper.min.css"
import pageSwiper from '@/components/page-swiper/page-swiper.vue'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    goodsId: {
      type: Number,
      default: 1
    }
  },
  components: {
    PageDraw,
    Swiper,
    SwiperSlide,
    pageSwiper
  },
  emits: ['changedDrawer'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const pageDialogVisible = computed(() => props.dialogVisible)
    const formatList = ref([])
    const changedDrawer = (flag) => {
      emit('changedDrawer', flag)
    }

    const ruleForm = reactive({
      tagId: '',
      category: ''
    })


    const updateGoodsDetail = computed(() => store.state.goods.updateGoodsDetail)
    const goodsFormatList = computed(() => store.state.goods.goodsFormatList)
    const goodsPicList = computed(() => store.state.goods.goodsPicList)
    const goodsTagList = computed(() => store.state.goods.goodsTagList)
    const goodsCategoryList = computed(() => store.state.goods.goodsCategoryList)

    const currentGoodsTag = ref({})

    watch(goodsTagList, (n) => {
      if (updateGoodsDetail.value != null) {
        currentGoodsTag.value = goodsTagList.value.find(item => item.tagId == updateGoodsDetail.value.tagId)
      }
    })
    watch(goodsCategoryList, (n) => {
      // console.log(n);
    })
    watch(updateGoodsDetail, (n) => {
      // console.log(n);
      if (updateGoodsDetail.value != null) {
        if (n.subCataId == null) {
          ruleForm.category = [n.cataId]
        } else {
          ruleForm.category = [n.cataId, n.subCataId]
        }
      }

    })




    const initPage = () => {
      store.dispatch('goods/getGoodsPicAction', { relateId: props.goodsId, relateKind: 2 })
      store.dispatch('goods/getGoodsFormat', { companyId: COMPANY_ID, goodId: props.goodsId })
      store.dispatch('goods/getGoodDetailAction', { companyId: COMPANY_ID, goodId: props.goodsId })
      store.dispatch('goods/getGoodsTagAction', { companyId: COMPANY_ID, tagKind: 2 })
      store.dispatch('goods/getGoodsCategoryList', { companyId: COMPANY_ID })
    }
    initPage()

    const formState = useState('goods', [])

    watch(() => props.goodsId, () => {
      initPage()
    })

    const handleEditClick = () => {
      router.push({
        path: '/main/goods/update', query: {
          goodsId: props.goodsId
        }
      })
    }

    const handleOperateClick = (type) => {
      let obj = {
        companyId: COMPANY_ID,
        goodStatusl: 1,
        goodId: props.goodsId
      }
      if (type == 1) {
        obj.goodStatus = 1
        store.dispatch('goods/updateGoodsStateAction', obj)
      } else {
        obj.goodStatus = 2
        store.dispatch('goods/updateGoodsStateAction', obj)
      }
    }


    emitter.on('refreshGoodsDrawerPage', () => {
      initPage()

    })

    return {
      pageDialogVisible,
      changedDrawer,
      formatList,
      updateGoodsDetail,
      goodsFormatList,
      goodsPicList,
      UPLOAD_BASE_URL,
      ...formState,
      ruleForm,
      goodsTagList,
      currentGoodsTag,
      goodsCategoryList,
      categoryProps,
      handleEditClick,
      handleOperateClick
    }

  }
})
</script>

<style scoped lang="less">
.goods-edit-draw {

  font-size: 12px;

  .goods-detail {
    height: 100%;

    .title {
      display: flex;
      position: relative;
      justify-content: space-between;
      padding-left: 16px;
      color: rgba(80, 80, 80, 1);
      font-size: 12px;
      margin: 20px 0;

      .other {
        color: rgba(42, 130, 228, 1);

        span {
          margin: 0 6px;
          cursor: pointer;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 20px;
        background-color: rgba(42, 130, 228, 1);
        left: 0px;
        top: -4px;
      }
    }

    .goods-name {
      color: rgb(42, 130, 228);
      font-size: 18px;
      font-weight: 550;
      margin: 16px 0;
    }

    .top-form {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }

    .goods-img {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .swiper {
        width: 100%;

        .swiper-item {

          .img-box {
            img {
              height: 180px;
              width: auto;
            }
          }

          &:deep(.swiper-slide) {}

        }
      }
    }

    .base-info {
      margin-top: 22px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;

      .base-info-list {
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;

        .info-item {
          display: flex;
          margin-bottom: 12px;
          width: 48%;
          margin: 0 1%;
          margin-bottom: 16px;

          .item-name {
            width: 60px;
            color: rgba(166, 166, 166, 1);
            font-size: 12px;
            margin-right: 26px;
          }

          .value {
            color: rgba(80, 80, 80, 1);
            font-size: 12px;
          }
        }
      }
    }

    .format-info {
      margin: 10px 0;

      .format-list {
        padding: 0 20px;
        font-size: 12px;
        border-bottom: 1px solid #eee;

        .item {
          display: flex;
          margin: 10px 0;

          div {
            flex: 1;
            line-height: 30px;
          }
        }
      }

      .titles {
        display: flex;
        height: 45px;
        line-height: 45px;
        background-color: rgb(239, 239, 239);
        font-size: 12px;
        margin-top: 16px;
        padding: 0 10px;
        font-size: 12px;

        div {
          flex: 1;
        }
      }
    }
  }
}
</style>




