export const contentTableConfig = {
  propList: [
    {
      prop: 'goodId',
      label: '序号',
      minWidth: '60',
      slotName: 'goodId'
    },
    { prop: 'goodName', label: '名称', minWidth: '200' , slotName: 'goodName'},
    {
      prop: 'category',
      label: '分类',
      minWidth: '200',
      slotName: 'category'
    },
    {
      prop: 'salePrice',
      label: '售价',
      minWidth: '120',
      slotName: 'salePrice'
    },
    {
      prop: 'promPrice',
      label: '划线价',
      minWidth: '120',
      slotName: 'promPrice'
    },
    {
      prop: 'isBuy',
      label: '已售卖',
      minWidth: '120',
      slotName: 'is_buy'
    },
    { label: '操作', minWidth: '200', slotName: 'handler', }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}
