<template>
  <div class="goods-list">
    <page-content :dataList="goodsInfoList" @selectionChange="selectionChange" :contentTableConfig="contentTableConfig"
      :isButtonStyle="true" @tableCellClick="handleTableCellClick">
      <template #is_buy>0</template>
      <template #handler="scope">
        <div class="handle-btns">
          <el-button type="danger" @click.stop="setGoodState(scope.row, 2)" v-if="scope.row.goodStatus == 1" plain
            size="mini">启用
          </el-button>
          <el-button @click.stop="setGoodState(scope.row, 1)" v-if="scope.row.goodStatus == 2" plain size="mini">停用
          </el-button>
          <el-button @click.stop="handleEditClick(scope.row)" plain size="mini" v-has="'goods:edit'">编辑</el-button>
          <el-button @click.stop="handleFenpeiClick(scope.row)" plain size="mini">上架</el-button>
        </div>
      </template>
      <template #promPrice="scope">¥{{ ((scope.row.promPrice) / 100).toFixed(2) }}</template>
      <template #salePrice="scope">¥{{ ((scope.row.salePrice) / 100).toFixed(2) }}</template>
      <template #goodName="scope">
        <div class="name">
          <img v-if="scope.row.pic != null" :src="scope.row.pic" alt />
          <!-- <img v-if="scope.row.pic != null" :src="scope.row.pic" alt /> -->
          <div class="goods-name">{{ scope.row.goodName }}</div>
        </div>
      </template>
      <template #category="scope">
        <template :key="item" v-for="(item, index) in scope.row.gtag">
          <span>{{ item.kindName }}</span>
          <span v-if="index != scope.row.gtag.length - 1">/</span>
        </template>
      </template>
    </page-content>
    <div class="el-pagination">
      <el-pagination v-model:currentPage="queryInfo.page" v-model:page-size="queryInfo.pagesize"
        :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="goodsInfoCount"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
    <goods-edit-draw :goodsId="currentGoodsId" @changedDrawer="handleGoodsEditdDrawer"
      :dialogVisible="pageDialogVisible"></goods-edit-draw>
    <distribute-dialog :goodsId="currentEditGoodsId" :dialogVisible="distributeDialogVisible"
      @changeDialogVisible="changeDistributeDialogVisible">
    </distribute-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config'
import { useRouter } from 'vue-router'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import GoodsEditDraw from './cpns/goods-edit-draw/goods-edit-draw.vue'
import { useStore } from 'vuex'
import { UPLOAD_BASE_URL } from '@/common/constant'
import emitter from '@/utils/eventbus'
import { ElMessage } from 'element-plus'
import distributeDialog from './cpns/distribute-dialog/distribute-dialog'
export default defineComponent({
  props: {

  },
  components: {
    PageContent,
    GoodsEditDraw,
    distributeDialog
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const queryInfo = {
      companyId: COMPANY_ID,
      keyword: "",
      status: 1,
      page: 1,
      pagesize: 10

    }
    const currentGoodsId = ref('')

    const handleEditClick = (item) => {
      router.push({
        path: '/main/goods/update', query: {
          goodsId: item.goodId
        }
      })
    }

    const goodsInfoList = computed(() => store.state.goods.goodsInfoList)
    const goodsInfoCount = computed(() => store.state.goods.goodsInfoCount)

    const initPage = () => {
      store.dispatch('goods/getgoodsInfoListAction', { ...queryInfo })
    }
    initPage()

    const setGoodState = (item, type) => {
      store.dispatch('goods/updateGoodsStateAction', {
        companyId: COMPANY_ID,
        goodStatus: type,
        goodId: item.goodId

      })
    }

    const handleTableCellClick = (value) => {
      currentGoodsId.value = value.goodId
      pageDialogVisible.value = true
    }

    const pageDialogVisible = ref(false)

    const handleGoodsEditdDrawer = (flag) => {

      pageDialogVisible.value = flag
    }
    const goodsCategoryList = computed(() => store.state.goods.goodsCategoryList)
    store.dispatch('goods/getGoodsCategoryList', { companyId: COMPANY_ID })

    const categoryProps = {
      checkStrictly: true,
      label: 'kindName',
      value: 'kindId',
    }


    const selectList = ref([])
    const selectionChange = (value) => {
      selectList.value = value
    }

    emitter.on('handleGoodsBatchSetState', (type) => {
      if (selectList.value.length == 0) {
        ElMessage.error('最少选择一个商品!')
        return
      }

      let goodIds = []
      selectList.value.forEach(item => {
        goodIds.push(item.goodId)
      })
      let obj = {
        companyId: COMPANY_ID,
        goodStatus: type,
        goodIds: goodIds.join(',')
      }
      store.dispatch('goods/setGoodsBatchSetStateAction', obj)
    })

    emitter.on('changeGoodsTopContentState', (type) => {
      queryInfo.status = type

    })

    emitter.on('changeGoodsTopContentSearch', (value) => {
      queryInfo.keyword = value
      initPage()
    })

    const handleSizeChange = pagesize => {
      queryInfo.pagesize = pagesize
      initPage()
    }

    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }


    const handleFenpeiClick = (item) => {
      currentEditGoodsId.value = item.goodId
      distributeDialogVisible.value = true
    }
    const distributeDialogVisible = ref(false)
    const changeDistributeDialogVisible = (flag) => {
      distributeDialogVisible.value = flag
    }

    const currentEditGoodsId = ref('')

    onUnmounted(() => {
      emitter.off('handleGoodsBatchSetState')
      emitter.off('changeGoodsTopContentState')
      emitter.off('changeGoodsTopContentSearch')
    })

    return {
      currentEditGoodsId,
      changeDistributeDialogVisible,
      distributeDialogVisible,
      handleFenpeiClick,
      handleSizeChange,
      handleCurrentChange,
      goodsInfoCount,
      goodsInfoList,
      selectionChange,
      contentTableConfig,
      COMPANY_ID,
      queryInfo,
      handleEditClick,
      handleTableCellClick,
      pageDialogVisible,
      handleGoodsEditdDrawer,
      currentGoodsId,
      goodsCategoryList,
      categoryProps,
      UPLOAD_BASE_URL,
      setGoodState
    }

  }
})
</script>

<style scoped lang="less">
.goods-list {
  margin-top: 20px;
  overflow: hidden;

  .name {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
}
</style>
