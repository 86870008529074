<template>
  <div class="add-rank-dialog">
    <page-dialog :width="25" v-if="dialogVisible" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible" title="商品分配门店">
      <div class="shop-list">
        <div class="titls">
          <div>门店名称</div>
          <!-- <div>上架状态</div> -->
          <div>分配状态</div>
        </div>
        <el-scrollbar height="250px">
          <el-checkbox-group @change="handleCheckChange" v-model="checkList">
            <template :key="item" v-for="item in shopList">
              <el-checkbox @click="checkBoxClick(item)" @change="handleCheckBoxChange" :label="item.shopId">
                <div class="item">
                  <div class="left">
                    <div class="name">{{ item.shopName }}</div>
                  </div>
                  <div class="right">
                    <el-tag size="small" v-if="checkList.findIndex(ele => ele == item.shopId) != -1" class="ml-2"
                      type="success">已分配
                    </el-tag>
                    <el-tag size="small" v-else class="ml-2" type="info">未分配</el-tag>
                  </div>

                </div>
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </el-scrollbar>
        <div class="pagination">
          <el-pagination v-model:currentPage="currentPage.page" v-model:page-size="currentPage.pagesize"
            :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="shopCount"
            @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="3" />
        </div>

      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rankCategory } from './config'
import emitter from '@/utils/eventbus'
import { shopGoodsBind, shopGoodsBindList } from '@/service/main/goods'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    goodsId: {
      type: Number,
      default: 0
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }


    const shopList = computed(() => store.state.serve.shopList)
    const shopCount = computed(() => store.state.serve.shopCount)



    const checkList = ref([])
    const shopCardBindList = ref([])
    watch(shopCardBindList, (n) => {
      checkList.value = []
      if (n != null) {
        n.forEach(item => {
          checkList.value.push(item.shopId)

        })
      }
    })
    const currentPage = reactive({
      page: 1,
      pagesize: 10,

    })

    const currentEditShop = reactive({
      id: 0,
      flag: false
    })

    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const handleSizeChange = (number) => {
      currentPage.pagesize = number
    }

    const handleCurrentChange = (number) => {
      currentPage.page = number
    }


    const getBindShopList = async () => {
      const res = await shopGoodsBindList(props.goodsId)
      shopCardBindList.value = res.data.list
    }

    const initPage = async () => {
      store.dispatch('serve/getshopListAction', {
        companyId: COMPANY_ID,
        page: currentPage.page,
        pagesize: currentPage.pagesize
      })
      getBindShopList()

    }





    const handleCheckBoxChange = async (flag) => {
      currentEditShop.flag = flag
      let res = await shopGoodsBind(currentEditShop.id, props.goodsId)
      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        getBindShopList()
      }

    }

    // emitter.on('changeCardRefreshDistributePage', () => {
    //   initPage()
    // })

    const handleCheckChange = (value) => {
      // console.log(value);
    }

    const checkBoxClick = (item) => {
      // console.log(item);
      currentEditShop.id = item.shopId
    }

    onUnmounted(() => {
      // emitter.off('changeCardRefreshDistributePage')
    })




    watch(() => props.goodsId, (n) => {
      initPage()
    })






    return {
      checkBoxClick,
      changeDialogVisible,
      rankCategory,
      shopList,
      shopCount,
      checkList,
      handleSizeChange,
      handleCurrentChange,
      currentPage,
      handleCheckBoxChange,
      handleCheckChange,
      currentEditShop,
      shopCardBindList,


    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-scrollbar) {
    padding: 0 10px;
  }

  .pagination {
    margin-top: 10px;
    text-align: right;
  }

  .shop-list {
    .titls {
      padding: 0 10px;
      display: flex;
      margin-bottom: 20px;
      background-color: rgb(239, 239, 239);

      div {
        flex: 1;
        line-height: 40px;

      }
    }

    &:deep(.el-checkbox) {
      display: inline-block;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right {
            position: relative;
            left: -8px;
          }

          .left,
          .right,
          .center {
            flex: 1;
          }

          .left {
            display: flex;


          }
        }
      }
    }
  }
}
</style>




