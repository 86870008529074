export const breadcrumbList = [
  {
    name: '商品管理',
    path: ''
  },
  {
    name: '产品列表',
    path: ''
  }
]

