<template>
  <div class="list">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="list-content">
      <top-content></top-content>
      <goods-list></goods-list>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import GoodsList from './cpns/goods-list/goods-list.vue'
import { breadcrumbList } from './config'
import TopContent from './cpns/top-content/top-content.vue'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    GoodsList,
    TopContent
  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.list {
  min-width: 960px;

  .list-content {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
  }
}
</style>




