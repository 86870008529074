<template>
  <div class="top-content">
    <div class="left">
      <el-button @click="handleAddGoodsClick" class="add-goods" type="primary" v-has="'goods:add'">
        <el-icon>
          <plus />
        </el-icon>添加商品
      </el-button>
      <el-dropdown trigger="click">
        <el-button type="primary">
          批量操作<el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="handleGoodsBatchClick(1)">上架</el-dropdown-item>
            <el-dropdown-item @click="handleGoodsBatchClick(2)">下架</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="right">
      <el-select @change="stateChange" v-model="goodsState" class="m-2" placeholder="销售状态" size="large">
        <el-option v-for="item in optList" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-input @change="handleSearchgChange" clearable class="search" prefix-icon="search" v-model="searchValue"
        placeholder="商品/服务名称，编码等" />
      <el-button @click="searchClick" type="primary">筛选</el-button>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import emitter from '@/utils/eventbus'
import { optList } from './config'
export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const router = useRouter()
    const handleAddGoodsClick = () => {
      router.push('/main/goods/add')
    }

    const handleGoodsBatchClick = (type) => {
      emitter.emit('handleGoodsBatchSetState', type)
    }

    const goodsState = ref(1)
    const stateChange = (value) => {
      // console.log(value);
      emitter.emit('changeGoodsTopContentState', value)
    }

    const searchValue = ref('')
    const searchClick = () => {
      emitter.emit('changeGoodsTopContentSearch', searchValue.value)
    }

    const handleSearchgChange = (value) => {
      if (value.length == 0) {
        emitter.emit('changeGoodsTopContentSearch', searchValue.value)
      }
    }

    return {
      handleSearchgChange,
      handleAddGoodsClick,
      handleGoodsBatchClick,
      optList,
      goodsState,
      stateChange,
      searchValue,
      searchClick
    }

  }
})
</script>

<style scoped lang="less">
// @media screen and (max-width: 1500px) {
//   .top-content {
//      justify-content: space-between;
//   }
// }



.top-content {
  display: flex;
  justify-content: space-between;

  .left {
    .add-goods {
      margin-right: 10px;
    }

    margin-right: 10px;
  }

  &:deep(.el-select) {
    margin-right: 10px;

    .el-input {
      max-width: 160px;
    }
  }

  .right {
    display: flex;

    &:deep(.el-input) {
      min-width: 240px !important;
    }

    .search {
      max-width: 180px;
      min-width: 150px;
      margin-right: 10px;
    }
  }
}
</style>




